.empty {
  display: flex;
  justify-content: center;
  font-size: 2rem;
  margin: 40px;
}

.navbarItem {
  list-style: none;
  color: #fff;
  text-transform: uppercase;
}

.navbarSesion {
  list-style: none;
  color: #fff;
  font-size: 0.8rem;
  text-transform: capitalize;
}

.formButton {
  background: var(--ciem-color-secondary);
  /* background-color: #fff; */
  border: 0px;
  border-radius: 5px;
  font-size: .8rem;
  color: #fff;  
  padding: 10px 30px;
  margin-right: 10px;
  text-transform: uppercase;
  cursor: pointer;
}

.formButton:hover,
.formButton:focus {
  background: var(--ciem-color-primary);
  color: #fff;
  border: 1px solid black;
  border-color: #fff;

  transition: background-color 1s ease-out;
  outline: 0;
  cursor: pointer;
}

.logoNavBar {
  display: block;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}