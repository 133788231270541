.expedienteGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 400px); 
  gap: 100px;
  padding: 50px;   
}

.contentMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 560px) {
  .expedienteGrid {    
    grid-template-columns: 100%; 
  }
}


.tituloParrafo {
  padding-top: 20px;
  font-size: 1rem;
  text-decoration: none;
}

.subtituloParrafo {
  font-size: 0.8rem;
  padding-bottom: 30px;
}

.heigthSpace {
  padding: 20px;
}


.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
}

.cardGuia {
  /* Add shadows to create the "card" effect */
  width: 26vw;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

/* Add some padding inside the card container */
.containerCard {
  padding: 2px 16px;
}

.cornerDialog {
  background: #666;
}