

.login {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;   
  justify-content: center;   
} 

.card {
  max-width: 40%;
  width: 50%;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  text-align: center;  
}

.formInput {  
  width: 80%;
  padding: 0.5em;
  border: none;
  background: none;
  outline: 0;
  color: #000;
  font-size: 1rem;  
  border-bottom: 1px solid var(--ciem-color-secondary);  
  margin: 1em 0;
}

.formButton {
  background: var(--ciem-color-secondary);
  border: 0;
  width: 50%;
  text-transform: uppercase;
  padding: 1em;
  margin-top: 1em;
  margin-bottom: 3em;
} 

.buttonSurvey {
  /* background: var(--ciem-color-secondary); */
  border: 0;
  width: 150px;
  text-transform: uppercase;
  padding: 1em;
  margin-top: 1em;
  margin-bottom: 3em;
} 

.buttonSurveyCategory {
  background: var(--ciem-color-secondary); 
  border: 0;
  width: 150px;
  text-transform: uppercase;
  padding: 1em;
  margin-top: 1em;
  margin-bottom: 3em;
} 
 
.colorGray {
  color: var(--ciem-color-primary);
}

.formButton:hover,
.formButton:focus {
  background: var(--ciem-color-primary);
  color: #fff;
  transition: background-color 1s ease-out;
  outline: 0;
  cursor: pointer;
}  

.parrafo {
  color: var(--ciem-color-text);
  padding-bottom: 20px;
}

.smallText {
  font-size: .8rem;
}

.smallBlack {
  font-size: .8rem;
  font-weight: 400;
  color: var(--ciem-color-primary);
}
  
.textField {
  text-align: center;
}

.titleForm {
  color: var(--ciem-color-text);
  text-transform: uppercase;
}

.subTitleForm {
  color: var(--ciem-color-secondary);    
}
 
.titleAlert {
  color: var(--ciem-color-alert);
  font-size: 0.8rem;
}

.surveyAnimation {
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}
