
 
.login {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;   
  justify-content: center;   
} 

.logo {
  width: 70px;
  padding-bottom: 20px;
}

.card {
  max-width: 350px;
  width: 50%;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  text-align: center;  
}

.formInput {  
  width: 80%;
  padding: 0.5em;
  border: none;
  background: none;
  outline: 0;
  color: #000;
  font-size: 1rem;  
  border-bottom: 1px solid var(--ciem-color-secondary);  
  margin: 1em 0;
}

.formButton {
  background: var(--ciem-color-secondary);
  border: 0;
  width: 80%;
  text-transform: uppercase;
  border-radius: 5px;
  padding: 1em;
  margin-top: 1em;
  margin-bottom: 3em;
} 

.formButton:hover,
.formButton:focus {
  background: var(--ciem-color-primary);
  color: #fff;
  transition: background-color 1s ease-out;
  outline: 0;
  cursor: pointer;
}  

.formButtonHome {
  background: var(--ciem-color-secondary);
  border: 0;
  width: 300px;
  text-transform: uppercase;
  border-radius: 5px;
  padding: 1em;
  margin-top: 1em;
  margin-bottom: 3em;
} 

.formButtonHome:hover,
.formButtonHome:focus {
  background: var(--ciem-color-primary);
  color: #fff;
  transition: background-color 1s ease-out;
  outline: 0;
  cursor: pointer;
}  
.formButtonHome[disabled],
.formButtonHome[disabled]:hover {
  background: var(--ciem-color-disabled-background-color);
  transition: none;
  cursor: not-allowed;
  color: #333;
}

.parrafo {  
  color: var(--ciem-color-text);
}

.parrafoLink {  
  padding-top: 10px;
  color: var(--ciem-color-text);
}

.smallText {
  font-size: .8rem;
}

.smallBlack {
  font-size: .8rem;
  font-weight: 400;
  text-decoration: none;
  color: var(--ciem-color-primary);
}

.textField {
  text-align: center;
}

.titleForm {
  color: var(--ciem-color-text);
  text-transform: uppercase;  
}

.subTitleForm {
  color: var(--ciem-color-secondary);  
  padding-bottom: 10px;
  font-size: .9rem;
}
 
.titleAlert {
  color: var(--ciem-color-alert);
  font-size: 0.8rem;
}

@media only screen and (max-width: 600px) {
  .card {
    width: 98%;
    padding: 10px;
  }
}