.spinner {
  display: flex;
  justify-content: center;
  margin: 30px;
  color: var(--ciem-color-secondary);
}

.spinning {
  animation: spinner 1s linear infinite;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid var(--ciem-color-primary); /* #f3f3f3; /* Light grey */
  border-top: 10px solid var(--ciem-color-secondary); /* #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1s linear infinite;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
