 
.expedienteCardTitle {  
  height: 40px;
  width: 500px;
  background: var(--ciem-color-primary);
  border-radius: 10px 10px;   
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  line-height: 40px;
}

.expedienteCard {  
  list-style: none;
  font-size: 1.5rem;
  /* text-align: center; */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;  
  width: 500px;  
  height: 350px; 
  border-radius: 20px 20px;  
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  padding-bottom: 20px;
}

 .expedienteCard:hover {
  opacity: 0.8;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.5);
} 

.especialidadCard:hover {
  opacity: 0.8;
  box-shadow: 0 4px 8px 0 rgba(145, 145, 145, 0.5);
}  

.itemLink:hover {
  opacity: 0.5;
}

.titleCard {
  text-transform: uppercase;
  font-size: 1rem;  
  position: absolute;
  margin-top: -70px;
  background: var(--ciem-color-primary);
  height: 30px;
  width: 100%;
}

.subTitleCard {
  font-size: .8rem;  
  padding: 10px 20px 0 20px;
}

.parrafoLink {  
  padding-top: 10px;
  color: var(--ciem-color-text);
}

.smallText {
  font-size: .8rem;
}


.title {
  font-size: .8rem;
  padding: 10px 0px 0px 10px;
  margin-bottom: 5px;
  color: var(--ciem-color-primary); 
}

.smallBlack {
  font-size: .8rem;
  padding: 0px 0px 10px 10px;   
  color: var(--ciem-color-primary); 
}
.smallCapitalize {
  text-transform: capitalize !important; 
}

/* se elimina la link de los textos */
.none {
  text-decoration: none;
}
.smallSubTitle {
  padding: 0px 0px 10px 10px;  
  color: #000;
  font-size: .8rem;
}

.buttonCard {  
  background: var(--ciem-color-secondary);  
  width: 50%;
  text-transform: uppercase;
  padding: 10px;
  margin: 10px;   
  border: none; 
  border-radius: 5px;
  color: #000;
} 

.buttonCardInactive {  
  background: var(--ciem-color-primary);  
  width: 50%;
  text-transform: uppercase;
  padding: 10px; 
  border: none; 
  margin-left: 15px;
  border-radius: 5px;
  color: #fff;
} 

.buttonCard:hover,
.buttonCard:focus {
  background: var(--ciem-color-primary);
  color: #fff;
  /* color: var(--ciem-color-primary); */

  transition: background-color 1s ease-out;
  outline: 0;
  cursor: pointer;
}


.formInput {  
  width: 50%;
  padding: 1em;
  border: none;
  background: #fff;
  outline: 0; 
  font-size: 1rem;  
  border-bottom: 1px solid var(--ciem-color-secondary);  
  margin: 1em;
}


@media (max-width: 560px) {
  .expedienteImage {
    width: 90%;
    height: auto;
  }

  .expedienteCard {
    width: 80%;    
  }

  .expedienteCardTitle {
    width: 80%;    
  }

}





 

/* --------------------------------     MODAL CUSTOM  -------------------------------- */

.modal {
  font-size: 12px;
  background: #fff;  
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.headerModal {  
  background: var(--ciem-color-secondary);  
  width: 100%;
  text-transform: uppercase;
  padding: 10px;      
  
} 

.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 30px;
  width: 37px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #000;
  color: #fff;
  border-radius: 18px;
  border: 1px solid #000;
}

/* --------------------------------  CSS STYLING   MODAL CUSTOM   -------------------------------- */


.popup-content {
  margin: auto;
  background: var(--ciem-color-secondary);
  width: 50%;
  padding: 5px;
}
.popup-arrow {
  color: rgb(255, 255, 255);
}
[role='tooltip'].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].popup-overlay {
  background: transparent;
}


/* --------------------------------     TABLE DIV CUSTOM  -------------------------------- */

.table {
  display: table;
  width: 100%;
  text-align:center;
  border-radius: 5px 5px;
}
.tableRow {
  display: table-row;
  width: 100%;
}

.tableRow:hover {
  background: #333;
  border-left: 5px solid var(--ciem-color-primary);  
  color: var(--ciem-color-primary);
  cursor: pointer;
}

.tableColAssay {
  display: table-cell;
  padding: 10px;
  width: 700px;
  background: #fff;
}
.tableColStatus {
  display: table-cell;
  padding: 10px;
  width: 200px;
  background: #fff;
}


@media screen and (max-width: 479px) {
  .table, .tableRow {
      display: block;
  }
  .tableCell {
      display:inline-block;
  }
}

/* --------------------------------     CUSTOM GROUP BUTTON  -------------------------------- */


.customButton {
  width: 140px;
  height: 30px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  background-color: var(--ciem-color-primary);
  color: white;  
  border: none;  
  cursor: pointer;
}

/* .customButton:first-child {
  border-radius: 20px 0 0 20px;
}  

.customButton:last-child {
  border-radius: 0 20px 20px 0;
}   */
 
.active{
  background-color: rgb(25, 26, 24);
  border: 1.5px solid rgb(25, 26, 24);
  }

.contentShare {
  width: 100%;
  height: 300px;
  background: var(--ciem-color-secondary);
}

.contentLeyenda {
  width: 80%;
  padding: 20px;  
  border-radius: 10px;
  background: var(--ciem-color-secondary);
}


.contentLeyendaText {
  font-size: .8rem;    
}
